<template>
  <div>
    <loading :loading="loading"></loading>
    <b-row class="" align-h="center">
      <b-col cols="12">
        <Card class="py-4 px-4 login-card">
          <template v-slot:content>
            <!--begin::Signin-->
            <div class='login-form login-signin'>
              <div class='text-center'>
                <div class="img logo purple"></div>
              </div>

              <b-alert class="mt-1" :show="errorMsg.length > 0" variant="danger">
                {{ $t(errorMsg) }}
              </b-alert>

              <div class='text-center'>
                <b-button
                  :to="{ name: 'login' }"
                  variant="primary"
                  class='font-weight-bold px-9 my-3 font-size-3'
                  style="margin: 0 auto; padding-right: 32px; padding-left: 32px;"
                >
                  {{ $t('AUTH.back_to_sign_in') }}
                </b-button>
              </div>
            </div>
            <!--end::Signin-->
          </template>
        </Card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApiService from '@/services/api.service'
import URLS from '@/config/urls'

import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'OAuthCallback',
  data () {
    return {
      loading: false,
      code: '',
      state: '',
      errorMsg: ''
    }
  },
  methods: {
    ...mapActions({
      getUser: 'auth/getUser'
    }),
    ...mapMutations({
      saveToken: 'auth/setAuth'
    })
  },
  computed: {
    ...mapGetters({
      isClientPlatform: 'staticInfo/isClientPlatform'
    })
  },
  mounted () {
    this.code = this.$route.query.code
    this.state = this.$route.query.state

    if (this.code && this.state) {
      this.loading = true

      ApiService.query(URLS.API.AUTH.OAUTH_CALLBACK, { code: this.code, state: this.state })
        .then(response => {
          if (response.status !== 200 || (typeof response.data.status !== 'undefined' && response.data.status === 'error') || typeof response.data.token === 'undefined') throw response

          this.saveToken(response.data.token)
        })
        .then(_ => this.getUser())
        .then(_ => { this.$router.push({ name: 'home' }) })
        .catch(err => {
          if (typeof err.data.data !== 'undefined') {
            switch (err.data.data) {
              case 'Authentication failure': {
                this.errorMsg = this.isClientPlatform ? 'AUTH.ERRORS.domain_not_included' : 'AUTH.ERRORS.domain_blocked'
                break
              }
              default: {
                this.errorMsg = 'AUTH.ERRORS.general'
              }
            }
          }

          this.showErrorToast(this.$t(this.errorMsg))
        })
        .then(_ => { this.loading = false })
    } else {
      // Missing state or code
      this.errorMsg = 'AUTH.ERRORS.oauth_missing_params'
    }
  }

}
</script>

<style>

</style>
